<template lang="pug">
v-container(grid-list-md fill-height)
  template(v-if="instructions.length")
    .instructions
      h1.section-title Основные правила работы
      p(
        v-for="(instruction, idx) in instructions"
        :key="idx"
      ) - Раздел «
        router-link.primary--text(
          :to="{ name: 'faq-instruction-detail', params: { id: instruction.id }}"
        ) {{ instruction.name }}
        | »
      v-btn.ml-0(
        color="primary"
        depressed
        @click="getInstructionsPdf"
        :loading="loadingDownload"
      ) Выгрузить в формате pdf
  template(v-else)
    the-loading
</template>

<script>
import MainService from '@/services/main';

export default {
  data() {
    return {
      instructions: [],
      loadingDownload: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$rHandler(
        MainService.getInstructions(),
        (response) => { this.instructions = response.data.data; },
      );
    },
    getInstructionsPdf() {
      this.loadingDownload = true;
      this.$rHandler(
        MainService.getInstructionsPdf(),
        (response) => {
          window.open(response.data.data.url);
        },
      ).finally(() => { this.loadingDownload = false; });
    },
  },
};
</script>
